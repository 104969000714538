import styled from 'styled-components';
import {Box, Button} from '@mui/material';

interface StyledLogoutButton {
  isLogoutButton: boolean;
}

const StyledModalContent = styled(Box)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 366px;
    height: 385px;
    border-radius: 20px;
    background: ${({theme}) => theme.baseColors.white};
    border-radius: 8;
    padding: 48px 0 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    outline: 'none';
    @media (max-width: 380px) {
      width: 350px;
    }
  }
`;

const StyledContent = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

const StyledCloseIcon = styled(Box)`
  && {
    position: absolute;
    right: 24px;
    top: 20px;
  }
`;

const StyleModalTItle = styled(Box)`
  && {
    color: ${({theme}) => theme.baseColors.text};
    text-align: center;
    font-family: Inconsolata, monospace;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
`;

const StyleModalMsg = styled(Box)`
  && {
    max-width: 300px;
    color: ${({theme}) => theme.baseColors.text};
    text-align: center;
    font-family: Inconsolata, monospace;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.07px;
  }
`;

const StyledButton = styled(Button)<StyledLogoutButton>`
  && {
    width: 331px;
    padding: 12px 24px;
    height: 46px;
    border-radius: 20px;
    background: ${(props) =>
      props.isLogoutButton
        ? props.theme.baseColors.primary2
        : props.theme.baseColors.primaryColor};
    color: ${(props) =>
      props.isLogoutButton
        ? props.theme.baseColors.text
        : props.theme.baseColors.white};
    font-family: Inconsolata, monospace;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.07px;
    text-transform: none;
    margin-top: ${(props) => (props.isLogoutButton ? '40px' : '20px')};
    &:hover {
      background: ${(props) =>
        props.isLogoutButton
          ? props.theme.baseColors.primary2
          : props.theme.baseColors.primaryColor};
    }
  }
`;

const StyledCloseButton = styled(Box)`
  position: absolute;
  right: 25px;
  top: 25px;
`;

export {
  StyledModalContent,
  StyledCloseIcon,
  StyledContent,
  StyleModalTItle,
  StyleModalMsg,
  StyledButton,
  StyledCloseButton,
};
