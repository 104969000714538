import Loader from '@shared/components/Loader';
import {PATH} from '@common/constants';
import {RootState} from '@store';
import {ReactNode, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';

import IdleDetector from '@shared/components/IdleTimer';
import Sidebar from '@pages/Dashboard/component/Sidebar';
import {MainWrapper, PageContainer} from '../App.style';
import ROUTES from './routes';

interface IPublicRouteProps {
  children: ReactNode;
  isAuthenticated: boolean;
  isConnected: boolean;
}

interface IPrivateRouteProps {
  children: ReactNode;
  isAuthenticated: boolean;
}

function PrivateRoute(props: IPrivateRouteProps) {
  const {children, isAuthenticated} = props;
  return isAuthenticated ? (children as JSX.Element) : <Navigate to='/login' />;
}

function PublicRoute(props: IPublicRouteProps) {
  const {children, isAuthenticated, isConnected} = props;
  if (isAuthenticated) {
    if (isConnected) {
      return <Navigate to='/reports' />;
    }
    return <Navigate to='/' />;
  }
  return children as JSX.Element;
}

function AppRoutes() {
  const {isLoggedIn, isConnected} = useSelector(
    (state: RootState) => state.login
  );
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {ROUTES.map((route) =>
          route.isPrivate ? (
            <Route
              key={route.name}
              path={route.path}
              element={
                <PrivateRoute isAuthenticated={isLoggedIn}>
                  {/* Left side  */}
                  <PageContainer>
                    {/* <IdleDetector /> */}
                    {/* <IdleDetectorPackage /> */}
                    <Sidebar />
                    <MainWrapper>
                      <route.component />
                    </MainWrapper>

                    {/* <Rightbar /> */}
                  </PageContainer>
                </PrivateRoute>
              }
            />
          ) : (
            <Route
              key={route.name}
              path={route.path}
              element={
                route.exact ? (
                  <PublicRoute
                    isAuthenticated={isLoggedIn}
                    isConnected={isConnected}>
                    <route.component />
                  </PublicRoute>
                ) : (
                  <route.component />
                )
              }
            />
          )
        )}
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
