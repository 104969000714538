import {lazy} from 'react';

const ROUTES = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../pages/Login')),
    name: 'LoginPage',
    isPrivate: false,
  },
  {
    path: '/register',
    exact: true,
    component: lazy(() => import('../pages/Login')),
    name: 'LoginPage',
    isPrivate: false,
  },
  // {
  //   path: '/loan-details/:loanId',
  //   exact: true,
  //   component: lazy(() => import('../pages/LoanDetails')),
  //   name: 'Loan Details',
  //   isPrivate: true,
  // },
  // {
  //   path: '/my-advance',
  //   exact: true,
  //   component: lazy(() => import('../pages/MyLoans')),
  //   name: 'My Advances',
  //   isPrivate: true,
  // },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../pages/Dashboard')),
    name: 'DashboardPage',
    isPrivate: true,
  },
  // {
  //   path: '/loan-application-process/:loanId?/:status?',
  //   exact: true,
  //   component: lazy(() => import('../pages/LoanApplication')),
  //   name: 'loan-application-process',
  //   isPrivate: true,
  // },
  // {
  //   path: '/past-payment-history/:loanId',
  //   exact: true,
  //   component: lazy(() => import('../pages/Transactions')),
  //   name: 'Past Payment History',
  //   isPrivate: true,
  // },
  // {
  //   path: '/repayment/:loanId',
  //   exact: true,
  //   component: lazy(() => import('../pages/ManualRepayment/container')),
  //   name: 'repayment',
  //   isPrivate: true,
  // },
  // {
  //   path: '/my-profile',
  //   exact: true,
  //   component: lazy(() => import('../pages/MyProfile')),
  //   name: 'my-profile',
  //   isPrivate: true,
  // },
  // {
  //   path: '/sales-platform/:step',
  //   exact: true,
  //   component: lazy(() => import('../pages/SalesPlatform')),
  //   name: 'sales-platform',
  //   isPrivate: true,
  // },
  {
    path: '/reports',
    exact: true,
    component: lazy(() => import('../pages/Reports')),
    name: 'ReportsPage',
    isPrivate: true,
  },
];

export default ROUTES;
