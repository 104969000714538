const PATH = {
  DASHBOARD: '/',
  REPORTS: '/reports',
};

const VERSIONS = {
  V1: '/ecc/pb/v1',
};

const AUTH_API_ENDPOINTS = {
  LOGIN: '/merchant/auth/login',
  LOGOUT: '/merchant/auth/logout',
  SELECT_BIN: '/merchant/auth/select-bin',
  GET_COUNTRIES: '/merchant/auth/countries-list',
  VALIDATE_MOBILE: '/merchant/auth/validate-mob-email',
};

const API_ENDPOINTS = {
  UPDATE_TO_PID: '/convert/update-to-pid',
  GET_INSTALL_URL: '/get-install-url',
  CONNECTOR_STATUS: '/connector/status',
  ANALYTICS: '/connector/analytics',
};

export {PATH, VERSIONS, API_ENDPOINTS, AUTH_API_ENDPOINTS};
