import NotificationsIcon from '@mui/icons-material/Notifications';
import {Box, Grid, ListItemText} from '@mui/material';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const GridItem = styled(Grid)`
  /* @media (max-width: 480px) {
    display: none;
  } */
`;

const SidebarWrapper = styled(Box)<{isMobScreen: boolean}>`
  height: 100%;
  background: #f2f2f2;
  position: fixed;
  width: 235px;
  padding: 0 12px;
  z-index: 2;
  ${(props) =>
    props.isMobScreen &&
    `
    height:0px;

    `}
  @media (max-width: 1120px) {
    width: 84px;
  }
`;
const SidebarItems = styled(Box)<{isMobScreen: boolean}>`
  width: 100%;
  ${(props) =>
    props.isMobScreen &&
    `
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 80px;
    align-items: center;
    background-color: #ffffff;
    padding: 10px; 
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    `}
  .stepper-section {
    @media (max-width: 1120px) and (min-width: 480px) {
      display: none;
    }
  }
`;

const Logo = styled.img`
  width: 129px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* For Extra Large (xl) screens and larger */

  margin: 36px auto 85px;

  @media (max-width: 1120px) {
    margin: 57px auto 85px;
    width: 50%;
  }
`;

const SidebarItem = styled(Link)<{
  isActive: boolean;
  isMobScreen: boolean;
}>`
  padding: 16px 16px 16px 16px;
  border-radius: 26px;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  background: ${(props) => (props.isActive ? '#d3f5e8' : 'transparent')};
  height: 56px;
  ${(props) =>
    props.isMobScreen &&
    `
    display: flex;
    width: 33%;
    height: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin:0;
    `}
`;

const ListItemTextContent = styled(ListItemText)<{
  isMobScreen: boolean;
  isActive: boolean;
}>`
  color: ${(props) => (props.isActive ? '#000000' : '#757575')};
  .MuiTypography-root {
    //styleName: M3/label/large;
    font-family: Inconsolata, monospace;
    font-size: 16px;
    font-weight: ${(props) => (props.isActive ? 600 : 500)};
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
  }
  && {
    ${(props) =>
      props.isMobScreen &&
      `margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .MuiTypography-root {
        font-weight: 600;
        font-size: 12px;
  }
    `}
  }
  @media (max-width: 1120px) {
    display: none;
  }
`;
const IconWrapper = styled(Box)<{isMobScreen: boolean; isActive: boolean}>`
  background: ${(props) =>
    props.isMobScreen && props.isActive ? '#d3f5e8' : 'transparent'};
  padding: ${(props) => props.isMobScreen && '4px 20px'};
  border-radius: ${(props) => props.isMobScreen && '20px'};
  padding: 4px 20px;
  border-radius: 20px;
  display: flex;
  @media (max-width: 1120px) and (min-width: 480px) {
    padding: 0px;
  }
`;
const NotificationsIconWrapper = styled(NotificationsIcon)<{
  activeItem: string;
}>`
  color: ${(props) =>
    props.activeItem === 'Notifications' ? '#000000' : '#757575'};
`;

const LogoutButton = styled(Box)`
  position: absolute;
  bottom: 30px;
  left: 55px;
  display: flex;
  cursor: pointer;
  @media (max-width: 1120px) {
    left: 35px;
  }
  .logout-text {
    font-family: Inconsolata, monospace;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: ${({theme}) => theme.baseColors.text};
    margin-left: 20px;
    @media (max-width: 1120px) and (min-width: 480px) {
      display: none;
    }
  }
`;

const AvatarWrapper = styled.div`
  display: 'flex';
  align-items: 'center';
  .MuiAvatar-root {
    width: fit-content;
    height: fit-content;
    border-radius: 0;
    cursor: pointer;
  }
`;

export {
  GridItem,
  NotificationsIconWrapper,
  IconWrapper,
  ListItemTextContent,
  SidebarWrapper,
  SidebarItems,
  Logo,
  SidebarItem,
  LogoutButton,
  AvatarWrapper,
};
