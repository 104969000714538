/* eslint-disable @typescript-eslint/no-explicit-any */
import alertReducer from '@core/api/store/alertReducer';
import loadingReducer from '@core/store/loadingReducer';

import loginReducer from '@pages/Login/auth/store/loginReducer';

import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const rootReducer = combineReducers({
  alert: alertReducer,
  loader: loadingReducer,
  login: loginReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loader', 'alert'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (
    getDefaultMiddleware: (arg0: {serializableCheck: boolean}) => any
  ) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const {dispatch} = store;

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export default store;
