/* eslint-disable react/require-default-props */
interface IIcon {
  color?: string;
  height?: string;
  width?: string;
}
export default function HomeIcon({color, height, width}: IIcon) {
  return (
    <svg
      fill='#000000'
      height={height}
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 486.4 486.4'
      xmlSpace='preserve'>
      <g>
        <g>
          <g>
            <path
              d='M404.587,35.439h-89.6V9.5c0-5.246-4.254-9.5-9.5-9.5H180.913c-5.246,0-9.5,4.254-9.5,9.5v25.939h-89.6
				c-5.246,0-9.5,4.254-9.5,9.5V476.9c0,5.246,4.254,9.5,9.5,9.5h322.774c5.246,0,9.5-4.254,9.5-9.5V44.939
				C414.087,39.692,409.833,35.439,404.587,35.439z M190.413,19h105.574v54.749H190.413V19z M395.087,467.4H91.313V54.439h80.1
				V83.25c0,5.246,4.254,9.5,9.5,9.5h124.574c5.246,0,9.5-4.254,9.5-9.5V54.439h80.1V467.4z'
            />
            <path
              d='M131.618,141.596h146.541c5.246,0,9.5-4.254,9.5-9.5c0-5.246-4.254-9.5-9.5-9.5H131.618c-5.246,0-9.5,4.254-9.5,9.5
				C122.118,137.342,126.372,141.596,131.618,141.596z'
            />
            <path
              d='M357.656,171.443H131.618c-5.246,0-9.5,4.254-9.5,9.5c0,5.246,4.254,9.5,9.5,9.5h226.038c5.246,0,9.5-4.254,9.5-9.5
				C367.156,175.697,362.902,171.443,357.656,171.443z'
            />
            <path
              d='M357.656,220.291H131.618c-5.246,0-9.5,4.254-9.5,9.5c0,5.246,4.254,9.5,9.5,9.5h226.038c5.246,0,9.5-4.254,9.5-9.5
				C367.156,224.545,362.902,220.291,357.656,220.291z'
            />
            <path
              d='M357.656,266.264H131.618c-5.246,0-9.5,4.254-9.5,9.5c0,5.246,4.254,9.5,9.5,9.5h226.038c5.246,0,9.5-4.254,9.5-9.5
				C367.156,270.518,362.902,266.264,357.656,266.264z'
            />
            <path
              d='M326.049,141.596h31.607c5.246,0,9.5-4.254,9.5-9.5c0-5.246-4.254-9.5-9.5-9.5h-31.607c-5.246,0-9.5,4.254-9.5,9.5
				C316.549,137.342,320.803,141.596,326.049,141.596z'
            />
            <path
              d='M261.631,373.82c0-2.536-1.015-4.968-2.818-6.752l-36.227-35.857c-3.588-3.552-9.331-3.677-13.069-0.281
				c-12.546,11.393-19.742,27.592-19.742,44.444c0,16.116,6.335,31.261,17.838,42.647c1.851,1.832,4.267,2.748,6.683,2.748
				s4.832-0.915,6.683-2.748l37.835-37.449C260.617,378.787,261.631,376.356,261.631,373.82z M215.053,397.153
				c-4.089-6.473-6.277-13.961-6.277-21.779c0-8.494,2.685-16.752,7.546-23.631l22.306,22.077L215.053,397.153z'
            />
            <path
              d='M341.655,332.729v-0.001l-0.001-0.001c-11.477-11.36-26.728-17.616-42.946-17.616c-15.234,0-29.811,5.618-41.044,15.819
				c-1.931,1.753-3.055,4.222-3.112,6.829c-0.056,2.607,0.962,5.122,2.816,6.956l29.405,29.105l-31.013,30.697
				c-1.803,1.784-2.817,4.216-2.817,6.752s1.014,4.967,2.817,6.752c11.477,11.36,26.73,17.616,42.949,17.616
				c33.517,0,60.786-27.034,60.786-60.263C359.493,359.259,353.158,344.115,341.655,332.729L341.655,332.729z M278.731,339.128
				c6.077-3.272,12.922-5.016,19.976-5.016c7.885,0,15.437,2.142,21.973,6.147l-20.403,20.195L278.731,339.128z M298.707,416.637
				c-7.887,0-15.438-2.142-21.973-6.147l37.611-37.228l19.87-19.667c4.09,6.474,6.278,13.961,6.278,21.779
				C340.493,398.127,321.748,416.637,298.707,416.637z'
            />
            <path
              d='M247.95,34.2h-32.3c-5.246,0-9.5,4.254-9.5,9.5c0,5.246,4.254,9.5,9.5,9.5h32.3c5.246,0,9.5-4.254,9.5-9.5
				C257.45,38.454,253.196,34.2,247.95,34.2z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

HomeIcon.default = {
  width: '20',
  height: '17',
  color: '#121212',
};
