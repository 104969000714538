import seedFlexLogo from '@assets/logo.png';
import seedflexTabLogo from '@assets/icons/seedflexTabLogo.svg';
import {PATH} from '@common/constants';
import {Avatar, useMediaQuery} from '@mui/material';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import PrimaryLogout from '@assets/icons/PrimaryLogout.svg';

import {RootState, dispatch} from '@store';
import {
  loginActions,
  loginReducerLogout,
} from '@pages/Login/auth/store/loginReducer';
import {logoutApi} from '@pages/Login/api';
import LogoutModal from '@pages/Login/component/LogoutModal';
import {useSelector} from 'react-redux';
import Reports from '@shared/icons/Reports';
import ConnectionIcon from '@shared/icons/Connection';
import {
  AvatarWrapper,
  GridItem,
  IconWrapper,
  ListItemTextContent,
  Logo,
  LogoutButton,
  SidebarItem,
  SidebarItems,
  SidebarWrapper,
} from './Sidebar.style';

export default function Sidebar() {
  const location = useLocation();
  const isTabScreen = useMediaQuery('(max-width:1120px)');
  const isMobScreen = useMediaQuery('(max-width:480px)');

  const [activeItem, setActiveItem] = useState('Integration');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === PATH.REPORTS) {
      setActiveItem('Analytics');
    } else if (location.pathname === '/') {
      setActiveItem('Integration');
    }
  }, [location.pathname]);

  const items = [
    {
      icon: (
        <Reports
          color={activeItem === 'Analytics' ? '#000000' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      link: PATH.REPORTS,
      text: 'Analytics',
    },
    {
      icon: (
        <ConnectionIcon
          color={activeItem === 'Integration' ? '#000000' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      link: PATH.DASHBOARD,
      text: 'Integration',
    },
  ];
  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const LogoutResponse = async () => {
    dispatch(loginReducerLogout());
    dispatch(loginActions.setIsLogout(true));
  };
  const handleLogout = () => {
    LogoutResponse();
  };
  const handleClickLogoutIcon = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <GridItem item xs={9.65} sm={4} md={2.45} lg={2.45} xl={1.8}>
      {location.pathname !== '/' && isMobScreen ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      ) : (
        <SidebarWrapper isMobScreen={isMobScreen}>
          <SidebarItems isMobScreen={isMobScreen}>
            {!isTabScreen && !isMobScreen && (
              <Logo src={seedFlexLogo} alt='Logo' />
            )}
            {isTabScreen && !isMobScreen && (
              <Logo src={seedFlexLogo} alt='TabLogo' />
            )}
            {items.map((item) => (
              <SidebarItem
                isMobScreen={isMobScreen}
                key={item.text}
                to={item.link}
                target={item.text.includes('Contact') ? '_blank' : '_self'}
                onClick={() => handleItemClick(item.text)}
                isActive={activeItem === item.text}>
                <IconWrapper
                  isMobScreen={isMobScreen}
                  isActive={activeItem === item.text}>
                  {item.icon}
                </IconWrapper>
                <ListItemTextContent
                  isMobScreen={isMobScreen}
                  isActive={activeItem === item.text}
                  primary={item.text}
                />
              </SidebarItem>
            ))}
          </SidebarItems>

          <LogoutButton onClick={handleClickLogoutIcon}>
            <AvatarWrapper>
              <Avatar alt='Logout' src={PrimaryLogout} />
            </AvatarWrapper>
            <div className='logout-text'>Log Out</div>
          </LogoutButton>

          <LogoutModal
            isOpen={open}
            handleLogout={handleLogout}
            handleCancle={handleCloseModal}
          />
        </SidebarWrapper>
      )}
    </GridItem>
  );
}
